import { isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  PLATFORM_ID,
  inject,
} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NavigationProgressBarComponent } from './core/layout/navigation-progress-bar/navigation-progress-bar.component';

@Component({
  selector: 'jrp-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  standalone: true,
  imports: [RouterOutlet, NavigationProgressBarComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  readonly isBrowser = isPlatformBrowser(inject(PLATFORM_ID));
}
