import { InjectionToken } from '@angular/core';
import LogRocket from 'logrocket';

export type LogRocketClient = typeof LogRocket;
export type LogRocketClientInit = typeof LogRocket.init;
export type LogRocketClientOptions = NonNullable<
  Parameters<LogRocketClientInit>[1]
>;
export type LogRocketClientNetworkOptions = NonNullable<
  LogRocketClientOptions['network']
>;
export type LogRocketRequestSanitizer = NonNullable<
  LogRocketClientNetworkOptions['requestSanitizer']
>;

export function logRocketClientFactory(): LogRocketClient {
  return LogRocket;
}

export const LOG_ROCKET = new InjectionToken<LogRocketClient>(
  'LogRocket client',
  {
    providedIn: 'root',
    factory: logRocketClientFactory,
  },
);
