import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
  computed,
  effect,
  inject,
  signal,
  untracked,
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { MatTooltip } from '@angular/material/tooltip';
import { ReplaySubject, filter, of, share, switchMap, take } from 'rxjs';
import { createLawArticleTitle } from '../helpers/law-article.helper';
import { LawArticlePreviewDialogComponent } from '../law-article-preview-dialog/law-article-preview-dialog.component';
import { LawArticleService } from '../law-article.service';

@Component({
  selector: 'jrp-law-article-reference',
  standalone: true,
  imports: [],
  templateUrl: './law-article-reference.component.html',
  styleUrl: './law-article-reference.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  hostDirectives: [MatTooltip],
  host: {
    role: 'button',
    '[attr.tabindex]': 'disabled() ? -1 : 1',
    '[attr.disabled]': 'disabled() || null',
    '[attr.aria-disabled]': 'disabled() || null',
    'aria-haspopup': 'dialog',
    class: 'jrp-law-article-reference',
    '(click)': 'openPreview()',
    '(keydown.enter)': 'openPreview()',
  },
})
export class LawArticleReferenceComponent {
  private readonly lawArticles = inject(LawArticleService);
  private readonly dialog = inject(MatDialog);
  private readonly tooltip = inject(MatTooltip, { host: true });

  @Input()
  set dataId(id: string | null) {
    untracked(() => this.id.set(id));
  }
  protected readonly id = signal<string | null>(null);

  lawArticle = toObservable(this.id).pipe(
    switchMap((id) => (id ? this.lawArticles.find(id) : of(null))),
    share({
      connector: () => new ReplaySubject(1),
      resetOnRefCountZero: false,
      resetOnComplete: false,
    }),
  );

  @Input()
  set dataNumber(number: string | null) {
    untracked(() => this.number.set(number));
  }
  protected readonly number = signal<string | null>(null);

  @Input({ required: true })
  set dataName(name: string) {
    untracked(() => this.name.set(name));
  }
  protected readonly name = signal('');

  readonly title = computed(() => {
    const number = this.number();
    const name = this.name();

    return number && createLawArticleTitle(number, name);
  });

  readonly disabled = computed(() => !this.id());

  constructor() {
    effect(() => {
      this.tooltip.disabled = this.disabled();
    });
    effect(() => {
      this.tooltip.message = this.title();
    });
  }

  openPreview(): void {
    this.lawArticle.pipe(take(1), filter(Boolean)).subscribe((lawArticle) => {
      this.dialog.open(LawArticlePreviewDialogComponent, {
        data: lawArticle,
        width: '960px',
        maxWidth: '100%',
        panelClass: 'jrp-full-screen-dialog',
      });
    });
  }
}
