import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import {
  isApiGatewayRequest,
  isApiRequest,
} from '../shared/api/helpers/api.helper';
import { TokenService } from './token.service';

export const legacyTokenAuthInterceptor: HttpInterceptorFn = (req, next) => {
  const token = inject(TokenService).value();
  if (token && (isApiRequest(req) || isApiGatewayRequest(req))) {
    const authenticatedRequest = req.clone({
      setHeaders: {
        'X-Authorization': token,
      },
    });
    return next(authenticatedRequest);
  }
  return next(req);
};
