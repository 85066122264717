import { CanActivateFn, UrlTree } from '@angular/router';
import { Observable, concat, defaultIfEmpty, first, skipWhile } from 'rxjs';
import { loggedInGuard } from './logged-in.guard';
import { userVerificationGuard } from './user-verification.guard';

export const authGuard: CanActivateFn = (route, state) => {
  const loggedIn = loggedInGuard(route, state) as Observable<boolean | UrlTree>;
  const userVerification = userVerificationGuard(route, state) as Observable<
    boolean | UrlTree
  >;

  return concat(loggedIn.pipe(first()), userVerification).pipe(
    skipWhile((result) => result === true),
    defaultIfEmpty(true),
  );
};
