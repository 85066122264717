import { Injectable, effect, inject } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { AuthService } from '../../../../auth/auth.service';
import { User } from '../../../../auth/user';
import { sanitizeLogRocketRequest } from './helpers/log-rocket-request.helper';
import { LOG_ROCKET } from './log-rocket';

@Injectable({
  providedIn: 'root',
})
export class LogRocketService {
  private readonly logRocket = inject(LOG_ROCKET);
  private readonly auth = inject(AuthService);

  constructor() {
    if (environment.logRocket.appId) {
      this.logRocket.init(environment.logRocket.appId, {
        network: {
          requestSanitizer: sanitizeLogRocketRequest,
        },
      });

      effect(() => {
        const user = this.auth.user();

        if (user) {
          this.identify(user);
        } else {
          this.reset();
        }
      });
    }
  }

  identify(user: User): void {
    this.logRocket.identify(user.id.toString(), {
      name: user.fullname,
      email: user.email,
    });
  }

  reset(): void {
    this.logRocket.startNewSession();
  }
}
