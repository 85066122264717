import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { map, of } from 'rxjs';
import { AuthService } from '../auth.service';

export const loggedInGuard: CanActivateFn = () => {
  const router = inject(Router);
  const auth = inject(AuthService);

  if (auth.isAuthenticated()) {
    return of(true);
  }
  return auth
    .signInByIPAddress()
    .pipe(map((token) => !!token || router.parseUrl('/connexion')));
};
