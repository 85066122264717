import { Route } from '@angular/router';
import { authGuard } from './auth/guards/auth.guard';
import { needActiveStatusGuard } from './auth/guards/need-status.guard';
import { signInWithTokenGuard } from './auth/guards/sign-in-with-token.guard';
import { needPermissionGuard } from './auth/permissions/need-permission.guard';

export const routes: Route[] = [
  {
    path: '',
    canActivate: [authGuard],
    loadComponent: () =>
      import('./core/layout/main-layout/main-layout.component').then(
        (m) => m.MainLayoutComponent,
      ),
    children: [
      {
        path: '',
        pathMatch: 'full',
        title: 'Accueil',
        loadComponent: () =>
          import('./home/home.component').then((m) => m.HomeComponent),
        data: {
          shrinkHeaderOnScroll: true,
        },
      },
      {
        path: 'decisions',
        loadChildren: () => import('./court-decision/court-decision.routes'),
      },
      {
        path: 'detect',
        canActivate: [
          needActiveStatusGuard,
          needPermissionGuard('access-detect'),
        ],
        loadChildren: () => import('./detect/detect.routes'),
      },
      {
        path: 'codes-et-lois',
        canActivate: [needActiveStatusGuard],
        loadChildren: () => import('./law-article/law-article.routes'),
      },
      {
        path: 'entreprises',
        canActivate: [needActiveStatusGuard],
        loadChildren: () => import('./company/company.routes'),
      },
      {
        path: 'dirigeants',
        canActivate: [needActiveStatusGuard],
        loadChildren: () => import('./leaders/leader.routes'),
      },
      {
        path: 'actualites',
        canActivate: [needActiveStatusGuard],
        loadChildren: () => import('./news/news.routes'),
      },
      {
        path: 'compte',
        loadChildren: () => import('./account/account.routes'),
        canActivate: [needPermissionGuard('access-account')],
      },
      {
        path: 'documents',
        canActivate: [
          needActiveStatusGuard,
          needPermissionGuard('access-documents'),
        ],
        loadChildren: () => import('./folders/folders.routes'),
      },
      {
        path: 'historique',
        canActivate: [
          needActiveStatusGuard,
          needPermissionGuard('access-search-history'),
        ],
        loadChildren: () => import('./search-history/search-history.routes'),
      },
      {
        path: 'veille-jurisprudentielle',
        canActivate: [
          needActiveStatusGuard,
          needPermissionGuard('access-alerts'),
        ],
        loadChildren: () => import('./alert/alert.routes'),
      },
      {
        path: 'contact',
        loadChildren: () => import('./contact/contact.routes'),
      },
    ],
  },
  {
    path: '',
    loadComponent: () =>
      import('./core/layout/simple-layout/simple-layout.component').then(
        (m) => m.SimpleLayoutComponent,
      ),
    children: [
      {
        path: 'connexion',
        title: 'Connexion',
        loadComponent: () =>
          import('./auth/sign-in/sign-in.component').then(
            (m) => m.SignInComponent,
          ),
        canActivate: [signInWithTokenGuard],
      },
      {
        path: 'mot-de-passe-oublie',
        title: 'Mot de passe oublié',
        loadComponent: () =>
          import('./auth/forgotten-password/forgotten-password.component').then(
            (m) => m.ForgottenPasswordComponent,
          ),
      },
      {
        path: 'verification',
        title: 'Vérification du compte',
        loadComponent: () =>
          import('./auth/verification/verification.component').then(
            (m) => m.VerificationComponent,
          ),
      },
    ],
  },
  {
    path: 'inscription',
    title: 'Inscription',
    loadComponent: () =>
      import('./auth/sign-up-form/sign-up-form.component').then(
        (m) => m.SignUpFormComponent,
      ),
  },
  { path: '**', redirectTo: '/' },
];
