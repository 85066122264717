import { environment } from '../../../../../../environments/environment';
import { LogRocketRequestSanitizer } from '../log-rocket';

export const sanitizeLogRocketRequest: LogRocketRequestSanitizer = (
  request,
) => {
  if (request.headers['X-Authorization']) {
    request.headers['X-Authorization'] = undefined;
  }

  const isSignInRequest =
    request.method === 'POST' &&
    request.url.startsWith(`${environment.apiUrl}/login`);
  const isSignUpRequest =
    request.method === 'POST' &&
    request.url.startsWith(`${environment.apiUrl}/users`);
  const isPasswordChangeRequest =
    request.method === 'POST' &&
    request.url.startsWith(`${environment.apiUrl}/user/password`);
  if (isSignInRequest || isSignUpRequest || isPasswordChangeRequest) {
    request.body = undefined;
  }
  return request;
};
