import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatAnchor, MatButton } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle,
} from '@angular/material/dialog';
import { RouterLink } from '@angular/router';
import { BypassSecurityTrustHtmlPipe } from '../../shared/pipes/bypass-security-trust-html.pipe';
import { LawArticle } from '../law-article';
import { LawArticleStatusComponent } from '../law-article-status/law-article-status.component';

@Component({
  selector: 'jrp-law-article-preview-dialog',
  standalone: true,
  imports: [
    DatePipe,
    MatButton,
    MatAnchor,
    RouterLink,
    LawArticleStatusComponent,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    BypassSecurityTrustHtmlPipe,
  ],
  templateUrl: './law-article-preview-dialog.component.html',
  styleUrl: './law-article-preview-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LawArticlePreviewDialogComponent {
  protected readonly lawArticle = inject<LawArticle>(MAT_DIALOG_DATA);
}
